<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom card-sticky">
          <div class="card-header">
            <h3 class="card-title font-weight-bolder text-dark">
              Import History
            </h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-8">Import History</div>
              <div class="col-md-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'

export default {
  name: 'ImportsHistory',
  data() {
    return {
      data: [],
    }
  },
  mounted() {},
  methods: {
    postTranslation(transaction, i) {
      try {
        setTimeout(() => {
          return ApiService.post('transactions', transaction)
        }, 2000 * i)
      } catch (e) {
        throw new Error(e)
      }
    },
  },
}
</script>
